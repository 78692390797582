import React from "react";
import TopNavbar from "./Components/TopNavbar/TopNavbar";
import BottomNavbar from "./Components/BottomNavbar/BottomNavbar";
import MidNavbar from "./Components/MidNavbar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Footer from "./Components/Footer";
import DomainOwnershipCheck from "./pages/DomainOwnershipCheck";
import TradeMarkSearch from "./pages/TradeMarkSearch";
const App = () => {
  return (
    <Router>
      <TopNavbar />
      <section className="mid-navbar">
        <MidNavbar />
      </section>
      <section className="bottom-navbar">
        <BottomNavbar />
      </section>
      <Routes>
        <Route exact path="/" element={<TradeMarkSearch />} />
        <Route exact path="/home" element={<TradeMarkSearch />} />
        <Route path="/trademark-search" element={<TradeMarkSearch />} />
        <Route path="/domain-ownership-check" element={<DomainOwnershipCheck />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;