import React from 'react'
import CheckDomainOwnership from "../Components/DomainOwnershipCheck/CheckDomainOwnership";

export default function DomainOwnershipCheck() {
  return (
    <div>
      <CheckDomainOwnership />
    </div>
  )
}
