import React from 'react'

export default function TradeMarkSearchDetailsTable(props) {
    console.log(props.data)
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto text-center">
                    <h1 className=" font-semibold leading-6 text-gray-900  text-3xl">Trademarks Registered</h1>
                    <p className="mt-4  text-gray-700 text-xl">
                        A list of all the Trademarks Registered with your Search Query
                    </p>
                </div>
                {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Add user
                    </button>
                </div> */}
            </div>
            <div className="mt-12 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Keyword
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Registration Number
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Registration Date
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Serial Number
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Expiration Date
                                    </th>
                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Description
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">View</span>
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {props.data.items.map((trademark) => (
                                    <tr key={trademark.serial_number}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {trademark.keyword}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trademark.registration_number}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trademark.registration_date}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trademark.serial_number}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trademark.expiration_date}</td>
                                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{trademark.description}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                View<span className="sr-only">, {trademark.serial_number}</span>
                                            </a>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
