import React from 'react'
import SearchTradeMark from '../Components/TradeMarkSearch/SearchTrademark'

export default function TradeMarkSearch() {
  return (
    <div>
      <SearchTradeMark />
    </div>
  )
}
