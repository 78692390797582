import React from 'react'
import spinner from "../Assets/spinner2.gif";
const Spinner = () => {
  return (
    <div className='text-center'>
      <img src={spinner} alt="spinner" srcset="" />
    </div>
  )
}

export default Spinner
