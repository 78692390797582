import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import axios from "axios";
import TradeMarkSearchDetailsTable from "./TradeMarkSearchDetailsTable";
import { BsArrowRight } from "react-icons/bs";
import "../Main/main.css";
import Newcomp from "./Newcomp";
import Lottie from "lottie-react";
import animationData from "../../Assets/data.json";
import Spinner from "../Spinner";
export default function SearchTradeMark() {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [tradeMarkSearchDetailsTableData, setTradeMarkSearchDetailsTableData] =
    useState(null);

  const handleClick = async (e) => {
    e.preventDefault();
    console.log(searchQuery);
    const options = {
      method: "GET",
      url: `https://uspto-trademark.p.rapidapi.com/v1/trademarkSearch/${searchQuery}/all`,
      headers: {
        "X-RapidAPI-Key": "e1407749cemshe854dd600182efep1ba796jsn7044146f7d1c",
        "X-RapidAPI-Host": "uspto-trademark.p.rapidapi.com",
      },
    };

    try {
      setLoading(true);
      const response = await axios.request(options);
      console.log(response.data);
      setTradeMarkSearchDetailsTableData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true)
      console.error(error);
    }
  };

  return (
    // <div className="space-y-10 divide-y divide-gray-900/10 mt-20">
    //   <div className="px-4 sm:px-0 text-center">
    //     <h2 className="lg:text-6xl md:text-4xl text-3xl font-semibold leading-7 text-gray-900">
    //       TradeMark Search
    //     </h2>
    //     <p className="lg:mt-6 mt-3 text-2xl leading-6 text-gray-500">
    //       Search for Your Trademark Here
    //     </p>
    //   </div>

    //   {/* <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"> */}
    //   <div className="main-div">
    //     {/* <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> */}
    //     {/* <div className="sm:col-span-4"> */}
    //     <div className="main-ai-form-div">
    //       <div className="input-container">
    //         <input
    //           type="text"
    //           name="text"
    //           onChange={(event) => setSearchQuery(event.target.value)}
    //           value={searchQuery}
    //           id="trademark_query"
    //           className="ai-form-input focus:outline-none focus:ring-0 focus:border-transparent"
    //           placeholder="Type Your Search Term Here...."
    //           style={{ borderBottom: "2px solid #bfbcbc" }}
    //         />
    //         <button
    //           type="submit"
    //           className="ai-form-button"
    //           onClick={handleClick}
    //         >
    //           Check <BsArrowRight className="ml-2" />
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   {/* </div> */}
    //   {/* </div> */}
    //   {tradeMarkSearchDetailsTableData && (
    //     <TradeMarkSearchDetailsTable data={tradeMarkSearchDetailsTableData} />
    //   )}
    //   {/* </form> */}
    // </div>
    <>
    
      <div className="h-[50vh] mt-10 md:h-[90vh]  lg:flex lg:flex-row  lg:items-center  lg:mt-[-6rem] lg:px-8">
        {/* TEXT CONTAINER */}
        <div className="flex items-center flex-start  flex-col lg:w-2/3 xl:w-1/2">
          <div className="">
            <h2 className="text-5xl md:text-6xl  font-bold mb-4 text-center">TradeMark Search</h2>
            <p className=" text-2xl font-semibold text-center text-gray-500"> Search for Your Trademark Here</p>
          </div>
        
          <div className=" mt-10">
            <form action="" className="flex items-center justify-center flex-col md:flex-row">
              <input
                onChange={(event) => setSearchQuery(event.target.value)}
                type="text" className=" text-4xl border-2 rounded-md "
                value={searchQuery}
                placeholder='search here' />
              <button onClick={handleClick} className="bg-red-800 text-white rounded-md px-[4rem] py-[1rem]  md:mt-1 md:mx-3 cursor-pointer mt-3">Search</button>
                {loading&& <Spinner/>}
              
            </form>
          </div>
        </div>
        {/* IMAGE CONTAINER */}
        <div className=" xl:w-1/2  lg:w-1/3 hidden  md:mt-10 md:flex md:items-center md:justify-center  ">
          <Lottie
            animationData={animationData}
            className="w-[60%] lg:w-[100%] lg:h-[100%]  2xl:h-[20%] 2xl:w-[90%]"
          />
        </div>

      </div>
    
    {
      !loading && 
      <div className="">
      {tradeMarkSearchDetailsTableData && (
        <TradeMarkSearchDetailsTable data={tradeMarkSearchDetailsTableData} />
      )}
    </div>
    }
    </>
    // <Newcomp/>
  );
}
