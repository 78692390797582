import { useState } from "react";
import { NavLink } from 'react-router-dom';
import logo from "../Assets/brandcartlogo.png";
import { AiOutlineMenu } from 'react-icons/ai';
import { IoCloseOutline } from 'react-icons/io5';
import { FaUserPlus } from "react-icons/fa";
import { BsHeart, BsCart } from "react-icons/bs";

const TopNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav class="nav">
      <div className="mid-nav-mobile-view">
        {isOpen ? (
          <IoCloseOutline onClick={handleClick} />
        ) : (
          <AiOutlineMenu onClick={handleClick} />
        )}
      </div>
      <NavLink to="https://b-randcart.myshopify.com" className="logo-link">
        <img src={logo} alt="Logo" className="logo-img" />
      </NavLink>
      {/* <div className="search">
          <input type="text" placeholder="Search..." />
          <svg
            focusable="false"
            className="icon icon--search "
            viewBox="0 0 21 21"
            role="presentation"
            style={{ color: "white" }}
          >
            <g
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              fillRule="evenodd"
            >
              <path d="M19 19l-5-5" strokeLinecap="square" />
              <circle cx="8.5" cy="8.5" r="7.5" />
            </g>
          </svg>
        </div> */}
      <div className="right">
        <NavLink to="https://brandcart.com/pages/seller-registration" className="header-navlink">
          <div className="seller">
            <p className="seller-p">Seller Registration</p>
            <FaUserPlus className="icon-user" />
          </div>
        </NavLink>
        <div className="wishlist">
          <NavLink to="https://brandcart.com/pages/seller-registration#" className="header-navlink">
            <div style={{ position: "relative", display: "inline-block" }}>
              <BsHeart style={{ width: "2rem", height: "1.5rem" }} />
              <span
                className="header_count_span"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  transform: "translate(50%, -50%)",
                }}
              >
                0
              </span>
            </div>
            <p>WishList</p>
          </NavLink>
        </div>
        <div className="cart">
          <NavLink to="https://brandcart.com/cart" className="header-navlink">
            <div style={{ position: "relative", display: "inline-block" }}>
              <BsCart style={{ width: "2rem", height: "1.5rem" }} />
              <span
                className="header_count_span"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  transform: "translate(50%, -50%)",
                }}
              >
                0
              </span>
            </div>
            <p>Cart</p>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
