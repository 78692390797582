import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import './BottomNavbar.css'
const BottomNavbar = () => {
  const [isDivVisible, setDivVisible] = useState(false);
  const [isDivVisibleColl, setDivVisibleColl] = useState(false);
  const [isDivVisibleAb, setDivVisibleAb] = useState(false);
  const [isDivVisibleRes, setDivVisibleRes] = useState(false);

 // {/************************************************* */ All Categories *****************************************/ }
  // on mouse enter of all categories hide all  other categores
  const handleNavLinkClick = () => {
    setDivVisible(!isDivVisible);


    // hiding collection,about,res on mosue enter of all collections
    setDivVisibleColl(false);
    setDivVisibleAb(false);
    setDivVisibleRes(false);
  };
  
  // {/************************************************* */ All Categories *****************************************/ }


  // {/************************************************* Collections **************************************************/ }
  const handleNavLinkClickColl = () => {
    setDivVisibleColl(!isDivVisibleRes);

    //making allcat,about,res false
    setDivVisible(false);
    setDivVisibleAb(false);
    setDivVisibleRes(false);
  };
  
// {/************************************************* Collections **************************************************/ }




  // {/**************************************************  About ************************************************* */ }
  const handleNavLinkClickAb = () => {
    setDivVisibleAb(!isDivVisibleAb);

    // hidigng allcat,coll,res
    setDivVisible(false);
    setDivVisibleColl(false);
    setDivVisibleRes(false);
  };
  
    // {/**************************************************  About ************************************************* */ }






  // {/*********************************************/ resources ***************************************************/ }
  const handleNavLinkClickRes = () => {
    setDivVisibleRes(!isDivVisibleAb);

    // hiding a;lcat,coll,about
    setDivVisible(false);
    setDivVisibleColl(false);
    setDivVisibleAb(false);
  };
  // {/*********************************************/ resources ***************************************************/ }




  // 
  return (
    <>
      <div className="secnav">
        <ul className="menuitem">
          <li className="list">
            <NavLink href="/" className="nav-link" 
            onClick={handleNavLinkClick} 
            onMouseEnter={handleNavLinkClick}
            >
              All Categories
              <MdOutlineKeyboardArrowDown />
            </NavLink>
          </li>
          <li className="list">
            <NavLink href="/" className="nav-link" onClick={handleNavLinkClickColl} onMouseEnter={handleNavLinkClickColl} >
              Collections
              <MdOutlineKeyboardArrowDown />
            </NavLink>
          </li>
          <li className="list">
            <NavLink href="/">All Extensions</NavLink>
          </li>
          <li className="list">
            <NavLink href="/" className="nav-link" onClick={handleNavLinkClickAb} onMouseEnter={handleNavLinkClickAb} >
              About
              <MdOutlineKeyboardArrowDown />
            </NavLink>
          </li>
          <li className="list">
            <NavLink href="/" className="nav-link" onClick={handleNavLinkClickRes} onMouseEnter={handleNavLinkClickRes} >
              Resources <MdOutlineKeyboardArrowDown />
            </NavLink>
          </li>
          <li className="list">
            <NavLink href="/">Blogs</NavLink>
          </li>
          <li className="list">
            <NavLink to="/trademark-search">Tademark Search</NavLink>
          </li>
        </ul>
      </div>
      {/* All Categories */}
      {/* {isDivVisible && } */}
      {
        isDivVisible && <div className="allcat" >
          <div className="allcatfirstrow">
            {/* <NavLink>Advertising & Marketing</NavLink> */}
            <ul className="allcatul">
              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/branding-agency-domains">Advertising & Marketing</NavLink></li>
              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/apparel-fashion-style">Apparel , Fashion & Style</NavLink></li>

              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/artificial-intelligence-bots-chat">Artificial Intelligence, Bots & Chat</NavLink></li>

              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/banks-financial-services">Bank , Finance , Insurance & Lending</NavLink></li>

              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/charity-non-profit">Charity & Non-Profit</NavLink></li>

              <li className="allcatlist"><NavLink to="https://brandcart.com/collections/consulting-professional-services">Consulting & Professional Services</NavLink></li>
            </ul>
          </div>
          <div className="allcatsecrow">
            {/* <NavLink>Education, Crypto , Fintech, Forex, Blockchain</NavLink> */}
            <ul className="allcatul">
              <li className="allcatlist"><NavLink > Crypto , Fintech, Forex, Blockchain</NavLink></li>
              <li className="allcatlist"><NavLink >Education, Learning & Training</NavLink></li>
              <li className="allcatlist"><NavLink>Health, Wellness and Ayurveda</NavLink></li>
              <li className="allcatlist"><NavLink>Metaverse & Virtual Reality</NavLink></li>
              <li className="allcatlist"><NavLink>News & Media, Books and PR</NavLink></li>
              <li className="allcatlist"><NavLink>Real Estate & Construction</NavLink></li>
              {/* <li className="allcatlist"><NavLink>Consulting & Professional Services</NavLink></li> */}
            </ul>
          </div>
          <div className="allcatthirdrow">
            {/* <NavLink>Sports, Outdoor & Adventure</NavLink> */}
            <ul className="allcatul">
              <li className="allcatlist"><NavLink >Sports, Outdoor & Adventure</NavLink></li>
              <li className="allcatlist"><NavLink >Startups & Incubators and Innovation</NavLink></li>
              <li className="allcatlist"><NavLink>Technology—Internet & Software</NavLink></li>
              <li className="allcatlist"><NavLink>Travel , Hospitality & Hotel</NavLink></li>
              <li className="allcatlist"><NavLink>Venture Capital & Investment</NavLink></li>
              <li className="allcatlist"><NavLink>All categories</NavLink></li>

            </ul>
          </div>
        </div>
      }

      {/* Collections */}
      {
        isDivVisibleColl && <div className="collections" >
          <ul>
            {/* <li className="coll">Collections</li> */}
            <li className="coll">Premium Domain Brands</li>
            <li className="coll">Ultra Premium Domain Brands</li>
            <li className="coll">1-3 Letter Domains</li>
            <li className="coll">4 Letter Brand Domains</li>
            <li className="coll">5 Letter Brand Domains</li>
            <li className="coll">Short & One word Domains</li>
            <li className="coll">Dictionary Word Brand Domains</li>
            <li className="coll">Clever & Curated Brand Domains</li>
            <li className="coll">Catchy, Trendy & Creative Domains</li>
            <li className="coll">Classic & Elegant Brand Names</li>
            <li className="coll">Location Based Names</li>
            <li className="coll">Acronym Brand Domains</li>
          </ul>
        </div>
      }
      {/* About */}
      {isDivVisibleAb &&
        <div className="about" >
          <div className="aboutfirstrow">
            {/* <NavLink>Advertising & Marketing</NavLink> */}
            <ul className="aboutul">
              <li className="aboutlist"><NavLink>About Brandcart.com</NavLink></li>
              <li className="aboutlist"><NavLink>Why Brandcart?</NavLink></li>
              <li className="aboutlist"><NavLink>Leadership</NavLink></li>
              <li className="aboutlist"><NavLink>Careers</NavLink></li>
              <li className="aboutlist"><NavLink>News & Events</NavLink></li>

            </ul>
          </div>
          <div className="aboutsecrow">
            <ul className="aboutul">
              <li className="aboutlist"><NavLink >Brandcart Services</NavLink></li>
              <li className="aboutlist"><NavLink >Brand Identity</NavLink></li>
              <li className="aboutlist"><NavLink>Brand Strategy & CX</NavLink></li>
              <li className="aboutlist"><NavLink>Brand Architecture</NavLink></li>
              <li className="aboutlist"><NavLink>Alliances & Partners</NavLink></li>
              <li className="aboutlist"><NavLink>Logonama</NavLink></li>
              <li className="aboutlist"><NavLink>Ideavert for Startups</NavLink></li>
              <li className="aboutlist"><NavLink>Brand Taglines</NavLink></li>
              <li className="aboutlist"><NavLink>UX Design & App Dev</NavLink></li>
              <li className="aboutlist"><NavLink>Buyer FAQ</NavLink></li>

            </ul>
          </div>
          <div className="abouthirdrow">
            {/* <NavLink>Sports, Outdoor & Adventure</NavLink> */}
            <ul className="aboutul">
              <li className="aboutlist"><NavLink >Reseller Services</NavLink></li>
              <li className="aboutlist"><NavLink >List Your Domains</NavLink></li>
              <li className="aboutlist"><NavLink>Promote Your Domains</NavLink></li>
              <li className="aboutlist"><NavLink>Seller FAQ</NavLink></li>
            </ul>
          </div>
        </div>}


      {/* Resources */}
      {
        isDivVisibleRes && <div className="resources">
          <ul>
            {/* <li className="coll">Collections</li> */}
            <li className="res">Industry Name Ideas</li>
            <li className="res">Customers & Testimonials</li>
            <li className="res">Curate Brandable Names</li>
            <li className="res">Becoming a Brand Scion</li>
            <li className="res">Domain Branding Tips</li>
            <li className="res">Ownmark Protection</li>
            <li className="res">Brandparker Service</li>
            <li className="res">Brandcart Blog</li>

          </ul>
        </div>
      }
    </>
  );
};

export default BottomNavbar;