import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const VerificationConfirmationModal = (props) => {
    const inputRef = useRef(null);

    const copyToClipboard = () => {
        // inputRef.current.select();
        // document.execCommand("copy");
        navigator.clipboard.writeText(props.verificationRecord);
    };
    
    return (
        <Transition.Root show={props.openVerificationConfirmationMoal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div class="relative bg-white rounded-lg shadow ">
                                    {/* <!-- Modal header --> */}
                                    <div class="bg-gray-600 flex   md:justify-center items-center p-4 border-b rounded-t  flex-col gap-1 ">
                                        <h3 class="mt-5 text-2xl font-semibold text-white ">
                                            Verify domain ownership via DNS record
                                        </h3>
                                        <p className='text-white text-xl text-left'>myblogsmantra.com </p>
                                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center " data-modal-hide="staticModal">
                                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span class="sr-only">Close modal</span>
                                        </button>
                                    </div>

                                    {/* <!-- Modal body --> */}
                                    <div class="p-6 space-y-6 ">
                                        <ul className='list-disc flex flex-col gap-4 px-4 '>
                                            <li className=" lg:flex-row">
                                                <p className="">Select record type:</p>
                                                <p className="">TXT (recommended)</p>
                                                <Link>Learn more</Link>
                                            </li>
                                            <li className="">
                                                Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)
                                            </li>
                                            <li className="">
                                                <p className="">Copy the TXT record below into the DNS configuration for <span className="font-bold">myblogsmantra.com</span></p>
                                                <div className="flex gap-3 mt-2 flex-col md:flex-row">
                                                    <input type="text" ref={inputRef} className='outline-none rounded-lg' placeholder='google site verification' value={props.verificationRecord} readOnly />
                                                    <button onClick={copyToClipboard} id="cp-ver-rec-btn" className='bg-gray-300 px-3 rounded-lg'>Copy</button>
                                                </div>

                                            </li>
                                            <li className="">
                                                Press verify below
                                            </li>
                                        </ul>
                                        <p className="">
                                            Note: DNS changes may take some time to apply. If Search Console doesn’t find the record immediately, wait a day and then try to verify againLearn more
                                        </p>

                                        {/* <div className="bg-gray-300 p-4 rounded-lg  md:w-1/2">
                                            <p className="text-gray-600 font-semibold">
                                                Can’t verify via Domain name provider?</p>
                                            <p className="text-gray-500">For more verification methods, try a URL prefix property instead</p>
                                        </div> */}
                                    </div>
                                    {/* <!-- Modal footer --> */}
                                    <div class="flex items-center justify-between p-6 space-x-2 border-t border-gray-200 rounded-b  flex-col md:flex-row">
                                        {/* <p className="uppercase text-[10px]">remove property</p> */}
                                        {/* <p className="uppercase font-semibold text-gray-600">Verify Later</p> */}
                                        <button onClick={props.pollVerificationStatus} data-modal-hide="staticModal" type="button" class="text-white bg-gray-500  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Verify</button>

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default VerificationConfirmationModal


/*


                    <div class="relative bg-white rounded-lg shadow ">

                        <div class="bg-red-500 flex   md:justify-center   border-b rounded-t   gap-1  p-4 items-center justify-between">
                            <h3 class="mt-5 text-xl font-semibold text-white ">
                                Ownership verification failed
                            </h3>
                            <button type="button" class="text-red-500 bg-transparent bg-white hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center " data-modal-hide="staticModal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>


                        <div class="p-6 space-y-6 w-full">
                            <p className="">Verification method:
                                <span className="font-bold"> Domain name provider</span></p>
                            <p className="">Failure reason:
                                <span className="font-bold"> Could not find your domain. Confirm that you provided the correct domain for your property.</span></p>
                            <p className="">Please fix your implementation and reverify, or use another verification method.
                            </p>
                        </div>

                        <div class="flex items-end p-6 space-x-2 border-t border-gray-200 rounded-b  flex-col md:flex-row">

                            <button data-modal-hide="staticModal" type="button" class="text-white bg-gray-500  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Okay</button>

                        </div>
                    </div>

*/




// spinner

//    <div id="staticModal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[90vh] max-h-full">
//    <div class="relative    ">
//        {/* <div class="relative  w-full md:w-2/3 max-w-2xl max-h-full"> */}
//        {/* <!-- Modal content --> */}
//        {/* first */}

//        {/* seconf */}
//        <div class="relative p-[3rem] bg-white rounded-lg shadow ">

//            <div class=" flex   md:justify-center  items-center justify-center  rounded-t   gap-4">
//                <h3 class=" text-xl font-semibold ">
//                    Checking verfication
//                </h3>
//                <div role="status">
//                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
//                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
//                    </svg>
//                    <span class="sr-only">Loading...</span>
//                </div>

//            </div>



//        </div>
//    </div>
// </div>
